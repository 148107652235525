import { createAction } from "../../utils/reducer/reducer.utils";
import { SETTING_ACTION_TYPES } from "./setting.types";
import { withMatcher, ActionWithPayload } from "../../utils/reducer/reducer.utils";
import { UserData } from "../../models/user.model";
import { DeviceSetting } from "../../models/device.model";


export type FetchSettingStart = ActionWithPayload<SETTING_ACTION_TYPES.FETCH_SETTING_START, UserData>
export const fetchSettingStart = withMatcher((user:UserData): FetchSettingStart => createAction(SETTING_ACTION_TYPES.FETCH_SETTING_START,user));

export type FetchSettingSuccess = ActionWithPayload<SETTING_ACTION_TYPES.FETCH_SETTING_SUCCESS, DeviceSetting[]>;
export const fetchSettingSuccess = withMatcher((deviceSettings:DeviceSetting[]): FetchSettingSuccess => createAction(SETTING_ACTION_TYPES.FETCH_SETTING_SUCCESS,deviceSettings));

export type FetchSettingFailed = ActionWithPayload<SETTING_ACTION_TYPES.FETCH_SETTING_FAILED, Error>;
export const fetchSettingFailed = withMatcher((error:Error):FetchSettingFailed => createAction(SETTING_ACTION_TYPES.FETCH_SETTING_FAILED,error));

export type UpdateSettingStart = ActionWithPayload<SETTING_ACTION_TYPES.UPDATE_SETTING_START, {user:UserData, settings:DeviceSetting}>
export const updateSettingStart = withMatcher((user:UserData, settings:DeviceSetting): UpdateSettingStart => createAction(SETTING_ACTION_TYPES.UPDATE_SETTING_START,{user, settings}));

export type UpdateSettingSuccess = ActionWithPayload<SETTING_ACTION_TYPES.UPDATE_SETTING_SUCCESS, DeviceSetting>;
export const updateSettingSuccess = withMatcher((deviceSettings:DeviceSetting): UpdateSettingSuccess => createAction(SETTING_ACTION_TYPES.UPDATE_SETTING_SUCCESS,deviceSettings));

export type UpdateSettingFailed = ActionWithPayload<SETTING_ACTION_TYPES.UPDATE_SETTING_FAILED, Error>;
export const updateSettingFailed = withMatcher((error:Error):UpdateSettingFailed => createAction(SETTING_ACTION_TYPES.UPDATE_SETTING_FAILED,error));

export type AddSettings = ActionWithPayload<SETTING_ACTION_TYPES.ADD_SETTINGS, {device_id:string, settings:DeviceSetting}>;
export const addSettings = withMatcher((device_id:string, settings:DeviceSetting): AddSettings => createAction(SETTING_ACTION_TYPES.ADD_SETTINGS,{device_id,settings}));