import { signIn, getAlertListRequest } from "../data.utils";
import {
  AddUserModel,
  Privileges,
  User,
  UserData,
} from "../../models/user.model";
import { location } from "../../models/locations.model";
import {
  Device,
  DeviceIdSetting,
  DeviceSetting,
  DeviceHighFreqData,
  DeviceLowFreqData,
} from "../../models/device.model";
import { Customer } from "../../models/customer.model";
import axios, { HttpStatusCode } from "axios";

import { toast } from "react-toastify";
import { Alert } from "../../models/alert.model";
import { DailyReport, Report, WeeklyReport } from "../../models/report.model";

 const url = "https://systemsupervisor.azurewebsites.net/"
//const url = process.env.REACT_APP_BACKEND_URL as string;

//Sign in a user
export const signInUser = async (username: string, password: string) => {
  const response = await signIn<UserData>(url, username, password);
  return response;
};

//Sign in user with a microsoft id token
export const signInUserMicrosoft = async (idtoken: string) => {
  const requestOptions = {
    method: "POST",
    url: url + "login/microsoft",
    headers: {
      Authorization: `Bearer ${idtoken}`,
      "Content-Type": "application/json",
    },
  };

  const response = await axios(requestOptions);
  return response.data;
}

//Allocate a device to a customer
export const allocateDevice = async (
  id: number,
  password: string,
  tagname: string,
  currentUser: UserData,
  customer_name?: string
) => {
  const { user, access_token } = currentUser;

  let customer;

  if (user.privileges === Privileges.EqonAdmin) {
    customer = customer_name;
    if (customer_name === "") {
      toast.error("Customer name is required");
      return;
    }
  } else {
    customer = user.customer;
  }

  var data = JSON.stringify({
    id: id,
    password: password,
    tagname: tagname,
    customer: customer,
  });

  if (!id || !password || !tagname || !customer || !access_token) return;
  const requestOptions = {
    method: "POST",
    url: url + "api/allocate",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    data,
  };

  const response = await axios(requestOptions);
  return response;
};

//Get the alert list from the backend
export const getAlertList = async (currentUser: UserData) => {
  const { access_token } = currentUser;

  const requestOptions = {
    method: "GET",
    headers: new Headers({
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    }),
  };

  const response = await getAlertListRequest(
    url + "api/alerts",
    requestOptions
  );
  return response;
};

//Get a list of devices id settings from the backend
export const getDeviceIdList = async (
  currentUser: UserData
): Promise<DeviceIdSetting[]> => {
  const { access_token } = currentUser;

  const response = await axios.get(url + `api/devices/idsettings`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access_token}`,
    },
  });

  return response.data;
};

//Get a list of devices from the backend
export const getDeviceList = async (
  currentUser: UserData
): Promise<Device[]> => {
  const { access_token } = currentUser;

  const response = await axios.get(url + `api/devices`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access_token}`,
    },
  });

  return response.data;
};

//Get a list of devices settings from the backend
export const getDeviceSettingsList = async (
  currentUser: UserData
): Promise<DeviceSetting[]> => {
  const { access_token } = currentUser;

  const response = await axios.get(url + `api/devices/settings`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access_token}`,
    },
  });

  return response.data;
};


//Get a list of devices highfreq data from the backend
export const getDeviceHighFreqData = async (
  currentUser: UserData
): Promise<DeviceHighFreqData[]> => {
  const { access_token } = currentUser;

  var config = {
    method: "get",
    url: url + "api/devices/highfreq",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
  };

  const response = await axios(config);

  return response.data;
};

//Get a list of devices lowfreq data from the backend
export const getDeviceLowFreqData = async (
  currentUser: UserData
): Promise<DeviceLowFreqData[]> => {
  const { access_token } = currentUser;

  var config = {
    method: "get",
    url: url + "api/devices/lowfreq",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
  };

  const response = await axios(config);

  return response.data;
};


//Patch settings for a device
export const patchDeviceSettings = async (
  settings: DeviceSetting,
  currentUser: UserData
): Promise<DeviceSetting> => {
  const { access_token } = currentUser;

  var config = {
    method: "patch",
    url: url + "api/devices/settings",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    data: settings,
  };

  const response = await axios(config);
  return response.data;
};

//Patch id settings for a device
export const patchDeviceIdSettings = async (
  settings: DeviceIdSetting,
  currentUser: UserData
): Promise<DeviceIdSetting> => {
  const { access_token } = currentUser;

  var config = {
    method: "patch",
    url: url + "api/devices/idsettings",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    data: settings,
  };

  const response = await axios(config);
  return response.data;

};


//Get customers from the backend
export const getCustomers = async (
  currentUser: UserData
): Promise<Customer[]> => {
  const { access_token } = currentUser;

  var config = {
    method: "get",
    url: url + "api/customers",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
  };

  const response = await axios(config);
  return response.data;
};

//Patch/update customer
export const patchCustomer = async (
  user: UserData,
  customer: Customer
): Promise<Customer> => {
  const { access_token } = user;

  var config = {
    method: "patch",
    url: url + "api/customers",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    data: customer,
  };

  try {
    const response = await axios(config);
    if (response.status === HttpStatusCode.Ok) {
      toast.success("customer updated successfully");
    } else {
      toast.error("customer update failed");
    }
    return response.data;
  } catch (error) {
    //Catching error, just return empty response as its not used. Display toast message.
    toast.error("customer update failed");
    const emptyResponse = {} as Customer;
    return emptyResponse;
  }
};

//Delete a customer
export const deleteCustomerAPI = async (
  user: UserData,
  id: string
): Promise<number> => {
  const { access_token } = user;
  var config = {
    method: "delete",
    url: url + "api/customers/" + id,
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
  };
  const response = await axios(config);
  return response.status;
};

//Add a customer
export const addCustomerAPI = async (
  currentUser: UserData,
  customer_name: string
): Promise<Customer> => {
  const { access_token } = currentUser;

  var data = JSON.stringify({
    customer_name: customer_name,
  });

  const config = {
    method: "post",
    url: url + "api/customers",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    data,
  };

  const response = await axios(config);

  return response.data;
};

//Create a user
export const createUser = async (
  currentUser: UserData,
  userToAdd: AddUserModel
): Promise<User> => {
  const { access_token } = currentUser;

  var data = JSON.stringify({
    username: userToAdd.username,
    password: userToAdd.password,
    full_name: userToAdd.full_name,
    email: userToAdd.email,
    privileges: +userToAdd.privileges,
    customer: userToAdd.customer,
  });

  const config = {
    method: "post",
    url: url + "users",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    data,
  };

  const response = await axios(config);

  return response.data;
};

//Get a user list from the backend
export const getUserList = async (currentUser: UserData): Promise<User[]> => {
  const { access_token } = currentUser;
  const config = {
    method: "get",
    url: url + "users",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
  };

  const response = await axios(config);

  return response.data;
};

//Delete a user
export const deleteUserAPI = async (
  user: UserData,
  username: string
): Promise<number> => {
  const { access_token } = user;
  var config = {
    method: "delete",
    url: url + "users/" + username,
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
  };
  /*We are not expecting a response after a delete, other than a statuscode...*/

  const response = await axios(config);
  return response.status;
};

//Patch/update a user
export const patchUser = async (
  user: UserData,
  username: string,
  full_name: string,
  privileges: number,
  email: string
): Promise<User> => {
  const { access_token } = user;

  var data = JSON.stringify({
    username: username,
    privileges: +privileges,
    full_name: full_name,
    email: email,
  });

  var config = {
    method: "patch",
    url: url + "users",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    data,
  };
  /*We are not expecting a response after a delete, other than a statuscode...*/

  const response = await axios(config);
  return response.data;
};

//Acknowledge an alert
export const ackAlert = async (
  user: UserData,
  alertId: string
): Promise<Alert> => {
  const { access_token } = user;

  var config = {
    method: "post",
    url: url + "api/alerts/" + alertId,
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
  };

  const response = await axios(config);
  return response.data;
};

//Get reports from the backend
export const getReports = async (user: UserData): Promise<Report[]> => {
  const { access_token } = user;

  var config = {
    method: "get",
    url: url + "api/reports",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
  };

  const response = await axios(config);
  return response.data;
};

//Get daily report from the backend
export const getDailyReport = async (user: UserData): Promise<DailyReport> => {
  const { access_token } = user;

  var config = {
    method: "get",
    url: url + "api/reports/day",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
  };

  const response = await axios(config);
  return response.data;
};

//Get weekly report from the backend
export const getWeeklyReport = async (
  user: UserData
): Promise<WeeklyReport> => {
  const { access_token } = user;

  var config = {
    method: "get",
    url: url + "api/reports/week",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
  };

  const response = await axios(config);
  return response.data;
};

//Delete an alert
export const deleteSingleAlert = async (
  user: UserData,
  alert_id: string
): Promise<HttpStatusCode> => {
  const { access_token } = user;
  var config = {
    method: "delete",
    url: url + "api/alerts/" + alert_id,
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
  };
  /*We are not expecting a response after a delete, other than a statuscode...*/

  const response = await axios(config);
  return response.status;
};

//Delete a device
export const deleteSingleDevice = async (
  user: UserData,
  device_id: string
): Promise<HttpStatusCode> => {
  const { access_token } = user;
  var config = {
    method: "delete",
    url: url + "api/devices/" + device_id,
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
  };
  /*We are not expecting a response after a delete, other than a statuscode...*/

  const response = await axios(config);
  return response.status;
};

//Renew access token
export const renewAccessToken = async (
  currentUser: UserData
): Promise<{ access_token: string; access_token_expires_at: Date }> => {
  const { refresh_token } = currentUser;

  var data = JSON.stringify({
    refresh_token: refresh_token,
  });

  const config = {
    method: "post",
    url: url + "token/renew_access",
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  const response = await axios(config);

  return response.data;
};

//Get customer locations
export const getCustomerLocations = async (
  currentUser: UserData
): Promise<location[]> => {
  const { access_token } = currentUser;
  const config = {
    method: "get",
    url: url + "api/locations",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
  };

  const response = await axios(config);

  return response.data;
};

//Delete a customer location
export const deleteCustomerLocation = async (
  currentUser: UserData,
  id: string
): Promise<HttpStatusCode> => {
  const { access_token } = currentUser;
  const config = {
    method: "delete",
    url: url + "api/locations/" + id,
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
  };
  const response = await axios(config);

  return response.status;
};

//Add a customer location
export const addCustomerLocation = async (
  currentUser: UserData,
  location_name: string,
  level: number
): Promise<location> => {
  const { access_token } = currentUser;

  var data = JSON.stringify({
    name: location_name,
    level: level,
  });

  const config = {
    method: "post",
    url: url + "api/locations",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    data,
  };
  const response = await axios(config);

  return response.data;
};

//Update customer location coordinates
export const updateCustomerLocationCoordinates = async (
  currentUser: UserData,
  location: location
): Promise<HttpStatusCode> => {
  const { access_token } = currentUser;

  var data = JSON.stringify({
    id: location.id,
    coord_lat: location.coord_lat,
    coord_lon: location.coord_lon,
  });

  const config = {
    method: "put",
    url: url + "api/locations/coords/update",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    data,
  };
  const response = await axios(config);

  return response.status;
};

//Update customer location name
export const updateCustomerLocationName = async (
  currentUser: UserData,
  location: location
): Promise<HttpStatusCode> => {
  const { access_token } = currentUser;

  var data = JSON.stringify({
    id: location.id,
    name: location.location_name,
  });

  const config = {
    method: "put",
    url: url + "api/locations/name/update",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    data,
  };
  const response = await axios(config);

  return response.status;
};
