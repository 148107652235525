import { createAction } from "../../utils/reducer/reducer.utils";
import { IDSETTING_ACTION_TYPES } from "./idsetting.types";
import { withMatcher, ActionWithPayload } from "../../utils/reducer/reducer.utils";
import { UserData } from "../../models/user.model";
import { DeviceIdSetting } from "../../models/device.model";


export type FetchIdSettingStart = ActionWithPayload<IDSETTING_ACTION_TYPES.FETCH_IDSETTING_START, UserData>
export const fetchIdSettingStart = withMatcher((user:UserData): FetchIdSettingStart => createAction(IDSETTING_ACTION_TYPES.FETCH_IDSETTING_START,user));

export type FetchIdSettingSuccess = ActionWithPayload<IDSETTING_ACTION_TYPES.FETCH_IDSETTING_SUCCESS, DeviceIdSetting[]>;
export const fetchIdSettingSuccess = withMatcher((deviceIdSettings:DeviceIdSetting[]): FetchIdSettingSuccess => createAction(IDSETTING_ACTION_TYPES.FETCH_IDSETTING_SUCCESS,deviceIdSettings));

export type FetchIdSettingFailed = ActionWithPayload<IDSETTING_ACTION_TYPES.FETCH_IDSETTING_FAILED, Error>;
export const fetchIdSettingFailed = withMatcher((error:Error):FetchIdSettingFailed => createAction(IDSETTING_ACTION_TYPES.FETCH_IDSETTING_FAILED,error));

export type UpdateIdSettingStart = ActionWithPayload<IDSETTING_ACTION_TYPES.UPDATE_IDSETTING_START, {user:UserData, settings:DeviceIdSetting}>
export const updateIdSettingStart = withMatcher((user:UserData, settings:DeviceIdSetting): UpdateIdSettingStart => createAction(IDSETTING_ACTION_TYPES.UPDATE_IDSETTING_START,{user, settings}));

export type UpdateIdSettingSuccess = ActionWithPayload<IDSETTING_ACTION_TYPES.UPDATE_IDSETTING_SUCCESS, DeviceIdSetting>;
export const updateIdSettingSuccess = withMatcher((deviceIdSettings:DeviceIdSetting): UpdateIdSettingSuccess => createAction(IDSETTING_ACTION_TYPES.UPDATE_IDSETTING_SUCCESS,deviceIdSettings));

export type UpdateIdSettingFailed = ActionWithPayload<IDSETTING_ACTION_TYPES.UPDATE_IDSETTING_FAILED, Error>;
export const updateIdSettingFailed = withMatcher((error:Error):UpdateIdSettingFailed => createAction(IDSETTING_ACTION_TYPES.UPDATE_IDSETTING_FAILED,error));

export type DeleteIdSetting = ActionWithPayload<IDSETTING_ACTION_TYPES.DELETE_IDSETTING, string>;
export const deleteIdSetting = withMatcher((device_id:string): DeleteIdSetting => createAction(IDSETTING_ACTION_TYPES.DELETE_IDSETTING,device_id));

export type AddIdSetting = ActionWithPayload<IDSETTING_ACTION_TYPES.ADD_IDSETTING, {device_id:string, tagname:string, idSettings:DeviceIdSetting}>;
export const addIdSetting = withMatcher((device_id:string, tagname:string, idSettings:DeviceIdSetting): AddIdSetting => createAction(IDSETTING_ACTION_TYPES.ADD_IDSETTING,{device_id,tagname,idSettings}));
