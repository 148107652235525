import { takeLatest, put, all, call } from "typed-redux-saga/macro";

import { DEVICE_ACTION_TYPES } from "./device.types";
import { allocateDevice, deleteSingleDevice, getDeviceList } from "../../utils/eqonapi/eqonapi.utils";
import { AllocateDeviceStart, DeleteDeviceStart, FetchDeviceStart, allocateDeviceFailed, allocateDeviceSuccess, deleteDeviceFailed, deleteDeviceSuccess, fetchDeviceFailed, fetchDeviceSuccess } from "./device.action";
import { addIdSetting, deleteIdSetting } from "../idsetting/idsetting.action";
import { addSettings } from "../setting/setting.action";
import { addLowFreqData } from "../lowfreqdata/lowfreqdata.action";
import { addHighFreqData } from "../highfreqdata/highfreqdata.action";


const maxTries = 3;   //number of tries for a failed api call. 1 means no retries 


// We get an action as a parameter. The action has a payload of UserData
export function* fetchDevices({payload: user}:FetchDeviceStart){
    let retries = 0;
    while(retries<maxTries){
        try{
            const response = yield* call(getDeviceList, user);   //response is the json response from fetch
            if(response){
                yield* put(fetchDeviceSuccess(response));           
                break;
            }
        }catch(error){
            retries++;
            if (retries === maxTries) {
                yield* put(fetchDeviceFailed(error as Error));
            }
        }
    }
}

export function* deleteDevice({payload: {user,device_id}}:DeleteDeviceStart){
    let retries = 0;
    while(retries<maxTries){
        try{
            const response = yield* call(deleteSingleDevice, user, device_id);   //response is the json response from fetch
            if(response){
                yield* put(deleteIdSetting(device_id));
                yield* put(deleteDeviceSuccess(response));    
                break;       
            }
        }catch(error){
            retries++;
            if (retries === maxTries) {
                yield* put(deleteDeviceFailed(error as Error));
            }
        }
    }
}

export function* registerDevice({payload: {user,device_id, password, tagname, customer}}:AllocateDeviceStart){
    let retries = 0;
    while(retries<maxTries){
        try{
            const response = yield* call(allocateDevice,device_id, password, tagname, user, customer);   //response is the json response from fetch
            
            if(response?.status === 201){
                //If we get a positive response, we add the device with its corresponding settings, etc. to the store
                yield* put(addIdSetting(response.data.id, tagname, response.data.id_settings));
                yield* put(addIdSetting(response.data.id, tagname, response.data.id_settings));
                yield* put(addSettings(response.data.id, response.data.settings));
                yield* put(addLowFreqData(response.data.id, response.data.low_freq_data));
                yield* put(addHighFreqData(response.data.id, response.data.high_freq_data));
                yield* put(allocateDeviceSuccess(response.status));    
                break;       
            }
        }catch(error){
            retries++;
            if (retries === maxTries) {
                yield* put(allocateDeviceFailed(error as Error));
            }
        }
    }
}

export function* onFetchDevicesStart() {
    yield* takeLatest(DEVICE_ACTION_TYPES.FETCH_DEVICE_START, fetchDevices)
}

export function* onDeleteDeviceStart() {
    yield* takeLatest(DEVICE_ACTION_TYPES.DELETE_DEVICE_START, deleteDevice)
}

export function* onAllocateDeviceStart() {
    yield* takeLatest(DEVICE_ACTION_TYPES.ALLOCATE_DEVICE_START, registerDevice)
}

/*"Listen" to the sagas*/ 
export function* deviceSagas() {
    yield* all([
        call(onFetchDevicesStart),
        call(onDeleteDeviceStart),
        call(onAllocateDeviceStart),
    ]);
}