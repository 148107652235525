import { createAction } from "../../utils/reducer/reducer.utils";
import { CUSTOMER_ACTION_TYPES } from "./customer.types";
import { withMatcher, Action, ActionWithPayload } from "../../utils/reducer/reducer.utils";
import { UserData } from "../../models/user.model";
import { Customer } from "../../models/customer.model";


export type FetchCustomerStart = ActionWithPayload<CUSTOMER_ACTION_TYPES.FETCH_CUSTOMER_START,{user:UserData}>;
export const fetchCustomerStart = withMatcher((user:UserData):FetchCustomerStart => createAction(CUSTOMER_ACTION_TYPES.FETCH_CUSTOMER_START, {user}));

export type FetchCustomerSuccess = ActionWithPayload<CUSTOMER_ACTION_TYPES.FETCH_CUSTOMER_SUCCESS, {customers: Customer[]}>;
export const fetchCustomerSuccess = withMatcher((customers: Customer[]):FetchCustomerSuccess => createAction(CUSTOMER_ACTION_TYPES.FETCH_CUSTOMER_SUCCESS, {customers}));

export type FetchCustomerFailed = ActionWithPayload<CUSTOMER_ACTION_TYPES.FETCH_CUSTOMER_FAILED, Error>;
export const fetchCustomerFailed = withMatcher((error:Error):FetchCustomerFailed => createAction(CUSTOMER_ACTION_TYPES.FETCH_CUSTOMER_FAILED,error));



export type AddCustomerStart = ActionWithPayload<CUSTOMER_ACTION_TYPES.ADD_CUSTOMER_START,{user:UserData,customer_name: string}>;
export const addCustomerStart = withMatcher((user:UserData,customer_name: string):AddCustomerStart => createAction(CUSTOMER_ACTION_TYPES.ADD_CUSTOMER_START, {user,customer_name}));

export type AddCustomerSuccess = Action<CUSTOMER_ACTION_TYPES.ADD_CUSTOMER_SUCCESS>;
export const addCustomerSuccess = withMatcher(() => createAction(CUSTOMER_ACTION_TYPES.ADD_CUSTOMER_SUCCESS));

export type AddCustomerFailed = ActionWithPayload<CUSTOMER_ACTION_TYPES.ADD_CUSTOMER_FAILED, Error>;
export const addCustomerFailed = withMatcher((error:Error):AddCustomerFailed => createAction(CUSTOMER_ACTION_TYPES.ADD_CUSTOMER_FAILED,error));



export type DeleteCustomerStart = ActionWithPayload<CUSTOMER_ACTION_TYPES.DELETE_CUSTOMER_START,{user:UserData,id: string}>;
export const deleteCustomerStart = withMatcher((user:UserData,id: string):DeleteCustomerStart => createAction(CUSTOMER_ACTION_TYPES.DELETE_CUSTOMER_START, {user,id}));

export type DeleteCustomerSuccess = Action<CUSTOMER_ACTION_TYPES.DELETE_CUSTOMER_SUCCESS>;
export const deleteCustomerSuccess = withMatcher(()=> createAction(CUSTOMER_ACTION_TYPES.DELETE_CUSTOMER_SUCCESS));

export type DeleteCustomerFailed = ActionWithPayload<CUSTOMER_ACTION_TYPES.DELETE_CUSTOMER_FAILED, Error>;
export const deleteCustomerFailed = withMatcher((error:Error):DeleteCustomerFailed => createAction(CUSTOMER_ACTION_TYPES.DELETE_CUSTOMER_FAILED,error));



export type UpdateCustomerStart = ActionWithPayload<CUSTOMER_ACTION_TYPES.UPDATE_CUSTOMER_START,{user:UserData,customer: Customer}>;
export const updateCustomerStart = withMatcher((user:UserData,customer: Customer):UpdateCustomerStart => createAction(CUSTOMER_ACTION_TYPES.UPDATE_CUSTOMER_START, {user,customer}));

export type UpdateCustomerSuccess = Action<CUSTOMER_ACTION_TYPES.UPDATE_CUSTOMER_SUCCESS>;
export const updateCustomerSuccess = withMatcher(()=> createAction(CUSTOMER_ACTION_TYPES.UPDATE_CUSTOMER_SUCCESS));

export type UpdateCustomerFailed = ActionWithPayload<CUSTOMER_ACTION_TYPES.UPDATE_CUSTOMER_FAILED, Error>;
export const updateCustomerFailed = withMatcher((error:Error):UpdateCustomerFailed => createAction(CUSTOMER_ACTION_TYPES.UPDATE_CUSTOMER_FAILED,error));
