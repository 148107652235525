import { DeviceLowFreqData } from "../../models/device.model";
import { AnyAction } from "redux";
import { fetchLowFreqDataSuccess, fetchLowFreqDataFailed, addLowFreqData } from "./lowfreqdata.action";

export type LowFreqDataState = {
    readonly deviceLowFreqData : DeviceLowFreqData[] | null;
    readonly isLoading : boolean;
    readonly error: Error | null;
}

const INITIAL_STATE : LowFreqDataState = {
    deviceLowFreqData: null,
    isLoading: false,
    error: null,
}


export const deviceLowFreqDataReducer = (state = INITIAL_STATE, action: AnyAction) => {

    if(fetchLowFreqDataSuccess.match(action)){
        return {             
            ...state,
            deviceLowFreqData: action.payload,
            error: null
        }
    }
    
    if(fetchLowFreqDataFailed.match(action)){
        return { 
            ...state,
            error: action.payload             
        } 
    }

    if(addLowFreqData.match(action)){
        const newDeviceLowFreqData = state.deviceLowFreqData
        newDeviceLowFreqData?.push(action.payload.lowFreqData) //add new lowFreqData for this device

        return {             
            ...state,
            deviceLowFreqData: newDeviceLowFreqData,
            error: null
        }
    }
    
    return state;
};