export enum USER_ACTION_TYPES  {
    SET_CURRENT_USER = "user/SET_CURRENT_USER",
    CHECK_USER_SESSION = "user/CHECK_USER_SESSION",
    EMAIL_SIGN_IN_START = "user/EMAIL_SIGN_IN_START",
    SIGN_IN_SUCCESS = "user/SIGN_IN_SUCCESS",
    SIGN_IN_FAILED = "user/SIGN_IN_FAILED",
    SIGN_OUT_START = "user/SIGN_OUT_START",
    SIGN_OUT_SUCCESS = "user/SIGN_OUT_SUCCESS",
    SIGN_OUT_FAILED = "user/SIGN_OUT_FAILED",
    ADD_USER_START = "user/ADD_USER_START",
    ADD_USER_SUCCESS = "user/ADD_USER_SUCCESS",
    ADD_USER_FAILED = "user/ADD_USER_FAILED",
    DELETE_USER_START = "user/DELETE_USER_START",
    DELETE_USER_SUCCESS = "user/DELETE_USER_SUCCESS",
    DELETE_USER_FAILED = "user/DELETE_USER_FAILED",
    FETCH_USERS_START =  "user/FETCH_USERS_START",
    FETCH_USERS_SUCCESS =  "user/FETCH_USERS_SUCCESS",
    FETCH_USERS_FAILED =  "user/FETCH_USERS_FAILED",
    UPDATE_USER_START =   "user/UPDATE_USER_START",
    UPDATE_USER_SUCCESS =   "user/UPDATE_USER_SUCCESS",
    UPDATE_USER_FAILED =   "user/UPDATE_USER_FAILED",
    REFRESH_TOKEN_START = "user/REFRESH_TOKEN_START",
    REFRESH_TOKEN_FAILED = "user/REFRESH_TOKEN_FAILED",
    REFRESH_TOKEN_SUCCESS = "user/REFRESH_TOKEN_SUCCESS",
    MICROSOFT_SIGN_IN_START = "user/MICROSOFT_SIGN_IN_START",
}