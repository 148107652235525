import { AnyAction } from "redux";
import { signInFailed, signInSuccess, signOutSuccess, signOutFailed, addUserSuccess, addUserFailed, fetchUserSuccess, fetchUserFailed, deleteUserSuccess, deleteUserFailed, updateUserSuccess, updateUserFailed, refreshTokenSuccess, refreshTokenFailed } from "./user.action";
import { User, UserData } from "../../models/user.model";
import { toast } from "react-toastify";

export type UserState = {
    readonly currentUser : UserData | null;
    readonly isLoading : boolean;
    readonly error: Error | null;
    readonly userList: User[] | null;
}

const INITIAL_STATE : UserState = {
    currentUser: null,
    isLoading: false,
    error: null,
    userList: null,
}



export const userReducer = (state = INITIAL_STATE, action: AnyAction) => {

    if(signInSuccess.match(action)){
        toast.success("Sign in successful")
        return {             
            ...state,
            currentUser: action.payload,
            error: null
        }
    }
    if(signOutSuccess.match(action)){
        toast.success("Sign out successful")
        return { 
            ...state,
            currentUser: action.payload,
            error:null
        }
    }
    
    if(signInFailed.match(action)){
        toast.error("Sign in failed")
        return { 
            ...state,
            error: action.payload             
        } 
    }

    if(signOutFailed.match(action)){
        toast.error("Sign out failed")
        return { 
            ...state,
            error: action.payload             
        } 
    }

    if(addUserSuccess.match(action)){
        toast.success("Add user successful")
        return {             
            ...state,
            error: null
        }
    }
    if(addUserFailed.match(action)){
        toast.error("Add user failed")
        return { 
            ...state,
            error:action.payload
        }
    }

    if(fetchUserSuccess.match(action)){
        return {             
            ...state,
            userList:action.payload,
            error: null
        }
    }
    if(fetchUserFailed.match(action)){
        toast.error("Failed getting users")
        return { 
            ...state,
            error:action.payload
        }
    }

    if(deleteUserSuccess.match(action)){
        toast.success("User deleted successfully")
        return {             
            ...state,
            error: null
        }
    }
    if(deleteUserFailed.match(action)){
        toast.error("Delete user failed")
        return { 
            ...state,
            error:action.payload
        }
    }

    if(updateUserSuccess.match(action)){
        toast.success("User updated successfully")
        return {             
            ...state,
            error: null
        }
    }
    if(updateUserFailed.match(action)){
        toast.error("Update user failed")
        return { 
            ...state,
            error:action.payload
        }
    }
    if(refreshTokenSuccess.match(action)){
        //We keep all the old user data, only access token and its duration changes.
        const newUser : UserData|null = state.currentUser
        if(newUser){
            newUser.access_token = action.payload.access_token
            newUser.access_token_expires_at = action.payload.access_token_expires_at
        }
        return{
            ...state,
            currentUser: newUser
        }
    }
    if(refreshTokenFailed.match(action)){
        toast.error("Failed to renew access token. Please log in again")
        //If the renew token fails to get a new access token we assume the backend is not responding, the renew token has expired or the session is blocked
        return{
            ...state,
            currentUser: null,
            error:action.payload
        }
    }
    
    return state;
};