import { DeviceIdSetting } from "../../models/device.model";
import { AnyAction } from "redux";
import {fetchIdSettingSuccess, fetchIdSettingFailed,updateIdSettingSuccess, updateIdSettingFailed, deleteIdSetting, addIdSetting } from "./idsetting.action";
import { toast } from "react-toastify";

export type IdSettingState = {
    readonly deviceIdSettings : DeviceIdSetting[] | null;
    readonly isLoading : boolean;
    readonly error: Error | null;
}

const INITIAL_STATE : IdSettingState = {
    deviceIdSettings: null,
    isLoading: false,
    error: null,
}


export const deviceIdReducer = (state = INITIAL_STATE, action: AnyAction) => {

    if(fetchIdSettingSuccess.match(action)){
        return {             
            ...state,
            deviceIdSettings: action.payload,
            error: null
        }
    }
    
    if(fetchIdSettingFailed.match(action)){
        return { 
            ...state,
            error: action.payload             
        } 
    }

    if(updateIdSettingSuccess.match(action)){

        toast.success("updated idSettings successfully")

        const newIdSettings = state.deviceIdSettings?.filter((deviceIdSetting) => deviceIdSetting.owner_id!==action.payload.owner_id) //remove old idSettings for this device
        newIdSettings?.push(action.payload) //add new idSettings for this device

        return {             
            ...state,
            deviceIdSettings: newIdSettings,
            error: null
        }
    }

    if(updateIdSettingFailed.match(action)){
        toast.error("failed to update idSettings for this device")
        return { 
            ...state,
            error: action.payload             
        } 
    }

    if(deleteIdSetting.match(action)){

        const newIdSettings = state.deviceIdSettings?.filter((deviceIdSetting) => deviceIdSetting.owner_id!==action.payload) //remove idSettings for this device

        return { 
            ...state,
            deviceIdSettings: newIdSettings,
            error: null        
        } 
    }

    if(addIdSetting.match(action)){

        const newIdSettings = state.deviceIdSettings
        newIdSettings?.push(action.payload.idSettings) //add new idSettings for this device
        
        return { 
            ...state,
            deviceIdSettings: newIdSettings,
            error: null        
        } 
    }

    
    return state;
};