import { AnyAction } from "redux";
import { Alert } from "../../models/alert.model";
import { acknowledgeAlertFailed, acknowledgeAlertSuccess, deleteAlertFailed, deleteAlertSuccess, fetchAlertsFailed, fetchAlertsSuccess } from "./alert.action";
import { toast } from "react-toastify";
import { HttpStatusCode } from "axios";

export type AlertState = {
    readonly alerts : Alert[] | null;
    readonly lastUpdate : Date;
    readonly error: Error | null;
}

const INITIAL_STATE : AlertState = {
    alerts: null,
    lastUpdate: new Date("2020-01-01T12:00:00.000000Z"),
    error: null,
}


export const alertsReducer = (state = INITIAL_STATE, action: AnyAction) => {

    if(fetchAlertsSuccess.match(action)){
        return {             
            ...state,
            alerts: action.payload,
            lastUpdate: new Date(),
            error: null
        }
    }
    
    if(fetchAlertsFailed.match(action)){
        toast.error("failed to get alerts")
        return { 
            ...state,
            error: action.payload             
        } 
    }

    if(acknowledgeAlertSuccess.match(action)){
        toast.success("alert acknowledged successfully")
        return {             
            ...state,
            alerts: state.alerts?.map((entry)=>{
                if(entry.id===action.payload.id){
                    return{...entry, acknowledged:true, acknowledged_by: action.payload.acknowledged_by, acknowledged_at: action.payload.acknowledged_at}
                }
                return entry
            }),
            error: null
        }
    }
    
    if(acknowledgeAlertFailed.match(action)){
        toast.error("failed to acknowledge alert")
        return { 
            ...state,
            error: action.payload             
        } 
    }

    if(deleteAlertSuccess.match(action)){
        if(action.payload.statusCode === HttpStatusCode.Ok){
            toast.success("alert deleted successfully")
        }else{
            return {             
                ...state,
                error: null
            }
        }
        //remove an alert with a specific id from the list oldAlertList
        const newAlertList = state.alerts?.filter((entry)=>entry.id!==action.payload.alert_id)
       
        return {             
            ...state,
            alerts: newAlertList,
            error: null
        }
    }
    
    if(deleteAlertFailed.match(action)){
        toast.error("failed to delete alert")
        return { 
            ...state,
            error: action.payload             
        } 
    }
    
    return state;
};