import { AnyAction } from "redux";
import { location } from "../../models/locations.model";
import { fetchLocationSuccess, fetchLocationFailed, createLocationSuccess, createLocationFailed, deleteLocationSuccess, deleteLocationFailed, updateLocationCoordsSuccess, updateLocationCoordsFailed, updateLocationNameSuccess, updateLocationNameFailed } from "./location.action";
import { toast } from "react-toastify";
import { HttpStatusCode } from "axios";


export type LocationState = {
    readonly locations : location[];
    readonly lastUpdate : Date;
    readonly error: Error | null;
}

const INITIAL_STATE : LocationState = {
    locations:  [],
    lastUpdate: new Date("2020-01-01T12:00:00.000000Z"),
    error: null,
}

export const locationsReducer = (state = INITIAL_STATE, action: AnyAction) => {

    if(fetchLocationSuccess.match(action)){
        return {             
            ...state,
            locations: action.payload,
            lastUpdate: new Date(),
            error: null
        }
    }

    if(fetchLocationFailed.match(action)){
        toast.error("failed to get locations")
        return { 
            ...state,
            error: action.payload             
        } 
    }

    if(createLocationSuccess.match(action)){
        toast.success("location created successfully")
        return {             
            ...state,
            locations: [...state.locations!, action.payload],
            error: null
        }
    }

    if(createLocationFailed.match(action)){
        toast.error("failed to create location")
        return { 
            ...state,
            error: action.payload             
        } 
    }

    if(updateLocationCoordsSuccess.match(action)){
        toast.success("location coordinates updated successfully")
        return {             
            ...state,
            error: null
        }
    }

    if(updateLocationCoordsFailed.match(action)){
        toast.error("failed to update location coordinates")
        return { 
            ...state,
            error: action.payload             
        } 
    }

    if(updateLocationNameSuccess.match(action)){
        if(action.payload === HttpStatusCode.Ok){
            toast.success("location name updated successfully")
        }else{        
            toast.error("failed to update location name")   
        }
        return {             
            ...state,
            error: null
        } 
    
    }

    if(updateLocationNameFailed.match(action)){
        toast.error("failed to update location name")
        return { 
            ...state,
            error: action.payload             
        } 
    }

    if(deleteLocationSuccess.match(action)){
        if(action.payload.statusCode === HttpStatusCode.NoContent){
            toast.success("deleted location successfully")
        }else{
            return {             
                ...state,
                error: null
            }
        }
        //remove a location with a specific id from the list oldLocationList
        const newLocationList = state.locations?.filter((entry)=>entry.id!==action.payload.location_id)
       
        return {             
            ...state,
            locations: newLocationList,
            error: null
        }
    }

    if(deleteLocationFailed.match(action)){
        toast.error("failed to delete location")
        return { 
            ...state,
            error: action.payload             
        } 
    }

    return state;
};