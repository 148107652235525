import { takeLatest, put, all, call } from "typed-redux-saga/macro";
import { AcknowledgeAlertStart, DeleteAlertStart, FetchAlertsStart, acknowledgeAlertFailed, acknowledgeAlertSuccess, deleteAlertFailed, deleteAlertSuccess, fetchAlertsFailed, fetchAlertsSuccess } from "./alert.action";
import { ackAlert, deleteSingleAlert, getAlertList } from "../../utils/eqonapi/eqonapi.utils";
import { ALERT_ACTION_TYPES } from "./alert.types";


const maxTries = 3;   //number of tries for a failed api call. 1 means no retries 

// We get an action as a parameter. The action has a payload of UserData
export function* fetchAlerts({payload}:FetchAlertsStart){
    let retries = 0;

    while(retries<maxTries){
        try{        
            const response = yield* call(getAlertList, payload);   //response is the json response from fetch
            if(response){
                yield* put(fetchAlertsSuccess(response));      
                break;     
            }
        }catch(error){
            retries++;
            if (retries === maxTries) {
                yield* put(fetchAlertsFailed(error as Error));
            }            
        }
    }
}

export function* acknowledgeAlert({payload:{user,alert_id}}:AcknowledgeAlertStart){
    let retries = 0;

    while(retries<maxTries){
        try{        
            const response = yield* call(ackAlert, user, alert_id);   //response is the json response from fetch
            if(response){
                yield* put(acknowledgeAlertSuccess(response));  
                break;              
            }
        }catch(error){
            retries++;
            if (retries === maxTries) {
                yield* put(acknowledgeAlertFailed(error as Error));
            }
        }
    }
}

export function* deleteAlert({payload:{user,alert_id}}:DeleteAlertStart){
    let retries = 0;

    while(retries<maxTries){
        try{        
            const response = yield* call(deleteSingleAlert, user, alert_id);   //response is the json response from fetch
            if(response){
                yield* put(deleteAlertSuccess(response,alert_id));     
                break;           
            }
        }catch(error){
            retries++;
                if (retries === maxTries) {
            yield* put(deleteAlertFailed(error as Error));
        }
        }
    }
}

export function* onFetchAlertsStart() {
    
    yield* takeLatest(ALERT_ACTION_TYPES.FETCH_ALERTS_START, fetchAlerts)
}

export function* onAcknowledgeAlertStart() {
    yield* takeLatest(ALERT_ACTION_TYPES.ACKNOWLEDGE_ALERT_START, acknowledgeAlert)
}

export function* onDeleteAlertStart() {
    yield* takeLatest(ALERT_ACTION_TYPES.DELETE_ALERT_START, deleteAlert)
}



/*"Listen" to the sagas*/ 
export function* alertSagas() {
    yield* all([
        call(onFetchAlertsStart),
        call(onAcknowledgeAlertStart),
        call(onDeleteAlertStart),
    ]);
}