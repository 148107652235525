import React, { useState } from "react";
import { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import "bootswatch/dist/slate/bootstrap.min.css";
import "./App.css";
// import 'bootstrap/dist/css/bootstrap.min.css';
import Spinner from "./components/spinner/spinner.component";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "ag-grid-community/styles/ag-theme-material.min.css"; // Optional theme CSS
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed

import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchAlertsStart } from "./store/alert/alert.action";
import { fetchLocationsStart } from "./store/location/location.action";
import { fetchLowFreqDataStart } from "./store/lowfreqdata/lowfreqdata.action";
import { fetchHighFreqDataStart } from "./store/highfreqdata/highfreqdata.action";
import { selectCurrentUser } from "./store/user/user.selector";
import { fetchSettingStart } from "./store/setting/setting.action";
import { fetchIdSettingStart } from "./store/idsetting/idsetting.action";
import { refreshTokenStart } from "./store/user/user.action";
import "./language/i18n";


const Devices = lazy(() => import("./components/devices/devices.component"));
const Devices2 = lazy(() => import("./components/devices/devices2.component"));
const Reports = lazy(() => import("./routes/reports/reports.components"));
const AddDeviceWizard = lazy(
  () => import("./routes/add-device-wizard/add-device-wizard.component")
);
const AlertTable = lazy(() => import("./routes/alerts/alerts.component"));
const NavBar = lazy(() => import("./components/navbar/navbar.component"));
const SubLocations = lazy(
  () => import("./routes/sublocations/sublocations.component")
);
const SubLocations2 = lazy(
  () => import("./routes/sublocations/sublocations2.component")
);
const Locations = lazy(() => import("./routes/locations/locations.component"));
const Locations2 = lazy(
  () => import("./routes/locations/locations2.component")
);
const CustomerManager = lazy(
  () => import("./routes/customermanager/customermanager.component")
);
const AddCustomer = lazy(
  () => import("./routes/customermanager/addcustomer.component")
);
const CustomerEditor = lazy(
  () => import("./routes/customermanager/customereditor.component")
);
const UserManagement = lazy(
  () => import("./routes/usermanagement/usermanagement.component")
);
const AddUser = lazy(() => import("./routes/usermanagement/adduser.component"));
const UserEditor = lazy(
  () => import("./routes/usermanagement/usereditor.component")
);
const LocationManager = lazy(
  () => import("./routes/locationmanager/locationmanager.component")
);
const AddLocationForm = lazy(
  () => import("./components/add-location-form/add-location-form.component")
);
const LocationEditor = lazy(
  () => import("./components/edit-location/edit-location.component")
);
const NavBar3 = lazy(() => import("./components/navbar3/navbar3.component"));
const Authentication = lazy(
  () => import("./routes/authentication/authentication.component")
);
const Settings = lazy(() => import("./routes/settings/settings.component"));
const Home = lazy(() => import("./routes/home/home.component"));
const DeviceSettings = lazy(
  () => import("./routes/device-settings/device-settings.component")
);
const Graphs = lazy(() => import("./routes/graphs/graphs.component"));

function App() {
  const [newDesign, setNewDesign] = useState(true); //Used to switch between the old and new design. Set to true to use the new design. Temporary solution.

  const currentUser = useSelector(selectCurrentUser);
  const dispatch = useDispatch();

  const refreshAlertList = () => {
    if (currentUser !== null) {
      dispatch(fetchAlertsStart(currentUser));
    }
  };

  const refreshLocationList = () => {
    if (currentUser !== null) {
      dispatch(fetchLocationsStart(currentUser));
    }
  };

  const refreshLowFreqData = () => {
    if (currentUser !== null) {
      dispatch(fetchLowFreqDataStart(currentUser));
    }
  };

  const refreshHighFreqData = () => {
    if (currentUser !== null) {
      dispatch(fetchHighFreqDataStart(currentUser));
    }
  };

  const refreshSettings = () => {
    if (currentUser !== null) {
      dispatch(fetchSettingStart(currentUser));
    }
  };

  const refreshIdSettings = () => {
    if (currentUser !== null) {
      dispatch(fetchIdSettingStart(currentUser));
    }
  };

  //Refresh data once, then every 5 minutes, as long as the user is logged in.
  useEffect(() => {
    if (currentUser !== null) {
      refreshAlertList();
      refreshLocationList();
      refreshLowFreqData();
      refreshHighFreqData();
      refreshSettings();
      refreshIdSettings();
      let id = setInterval(() => {
        refreshAlertList();
        refreshLocationList();
        refreshLowFreqData();
        refreshHighFreqData();
        refreshSettings();
        refreshIdSettings();
      }, 300000);
      return () => clearInterval(id);
    }
  }, [currentUser]);

  let refreshAccessToken = () => {
    if (currentUser !== null) {
      dispatch(refreshTokenStart(currentUser));
    }
  };

  //Renew access token every 10 minutes, as long as the user is logged in.
  useEffect(() => {
    if (currentUser !== null) {
      let id = setInterval(() => {
        refreshAccessToken();
      }, 600000);
      return () => clearInterval(id);
    }
  }, [currentUser]);

  return (
    <Suspense fallback={<Spinner />}>
      <ToastContainer
        autoClose={1500}
        theme="dark"
        hideProgressBar
        pauseOnFocusLoss={false}
      />
      <NavBar3></NavBar3>
      <Routes>
          {/* <Route path="/" element={<NavBar/>}>   */}
          <Route index element={<Authentication />} />
          <Route path="home" element={<Home />} />
          {newDesign ? (
            <Route path="devices/:type/:loc" element={<Devices2 />} />
          ) : (
            <Route path="devices/:type/:loc" element={<Devices />} />
          )}
          <Route path="reports" element={<Reports />} />
          <Route path="settings" element={<Settings />} />
          <Route path="alerts" element={<AlertTable />} />
          <Route path="auth" element={<Authentication />} />
          <Route path="addDevice" element={<AddDeviceWizard />} />
          {newDesign ? (
            <Route path="locations/:iType" element={<SubLocations2 />} />
          ) : (
            <Route path="locations/:iType" element={<SubLocations />} />
          )}
          <Route path="device/:deviceId" element={<DeviceSettings />} />
          <Route path="settings/user" element={<UserManagement />} />
          {newDesign ? (
            <Route path="installtypes" element={<Locations2 />} />
          ) : (
            <Route path="installtypes" element={<Locations />} />
          )}
          <Route path="settings/customer" element={<CustomerManager />} />
          <Route path="settings/customer/add" element={<AddCustomer />} />
          <Route
            path="settings/customer/:customerId"
            element={<CustomerEditor />}
          />
          <Route path="settings/user/add" element={<AddUser />} />
          <Route path="settings/user/:user_name" element={<UserEditor />} />
          <Route
            path="settings/locations/:level"
            element={<LocationManager />}
          />
          <Route
            path="settings/locations/:level/add"
            element={<AddLocationForm />}
          />
          <Route
            path="settings/location/:locationId"
            element={<LocationEditor />}
          />
          <Route path="graphs" element={<Graphs />} />
          {/* </Route>   */}
      </Routes>
    </Suspense>
  );
}

export default App;
