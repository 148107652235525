import { takeLatest, put, all, call } from "typed-redux-saga/macro";

import { HIGHFREQDATA_ACTION_TYPES } from "./highfreqdata.types"; 
import { getDeviceHighFreqData } from "../../utils/eqonapi/eqonapi.utils";
import { FetchHighFreqDataStart, fetchHighFreqDataFailed, fetchHighFreqDataSuccess} from "./highfreqdata.action";

const maxTries = 3;   //number of tries for a failed api call. 1 means no retries 

// We get an action as a parameter. The action has a payload of UserData
export function* fetchHighFreqData({payload}:FetchHighFreqDataStart){
    let retries = 0;
    while(retries<maxTries){
        try{        
            const response = yield* call(getDeviceHighFreqData, payload);   //response is the json response from fetch
            if(response){
            yield* put(fetchHighFreqDataSuccess(response));           
            break;
            }
        }catch(error){
            retries++;
            if (retries === maxTries) {
                yield* put(fetchHighFreqDataFailed(error as Error));
            }
        }
    }
}

export function* onFetchHighFreqDataStart() {
    yield* takeLatest(HIGHFREQDATA_ACTION_TYPES.FETCH_HIGHFREQDATA_START, fetchHighFreqData)
}


/*"Listen" to the sagas*/ 
export function* deviceHighFreqDataSagas() {
    yield* all([
        call(onFetchHighFreqDataStart),
    ]);
}