import { createAction } from "../../utils/reducer/reducer.utils";
import { LOWFREQDATA_ACTION_TYPES } from "./lowfreqdata.types";
import { withMatcher, ActionWithPayload } from "../../utils/reducer/reducer.utils";
import { UserData } from "../../models/user.model";
import { DeviceLowFreqData } from "../../models/device.model";


export type FetchLowFreqDataStart = ActionWithPayload<LOWFREQDATA_ACTION_TYPES.FETCH_LOWFREQDATA_START, UserData>
export const fetchLowFreqDataStart = withMatcher((user:UserData): FetchLowFreqDataStart => createAction(LOWFREQDATA_ACTION_TYPES.FETCH_LOWFREQDATA_START,user));

export type FetchLowFreqDataSuccess = ActionWithPayload<LOWFREQDATA_ACTION_TYPES.FETCH_LOWFREQDATA_SUCCESS, DeviceLowFreqData[]>;
export const fetchLowFreqDataSuccess = withMatcher((deviceLowFreqData:DeviceLowFreqData[]): FetchLowFreqDataSuccess => createAction(LOWFREQDATA_ACTION_TYPES.FETCH_LOWFREQDATA_SUCCESS,deviceLowFreqData));

export type FetchLowFreqDataFailed = ActionWithPayload<LOWFREQDATA_ACTION_TYPES.FETCH_LOWFREQDATA_FAILED, Error>;
export const fetchLowFreqDataFailed = withMatcher((error:Error):FetchLowFreqDataFailed => createAction(LOWFREQDATA_ACTION_TYPES.FETCH_LOWFREQDATA_FAILED,error));

export type AddLowFreqData = ActionWithPayload<LOWFREQDATA_ACTION_TYPES.ADD_LOWFREQDATA, {device_id:string, lowFreqData:DeviceLowFreqData}>;
export const addLowFreqData = withMatcher((device_id:string, lowFreqData:DeviceLowFreqData): AddLowFreqData => createAction(LOWFREQDATA_ACTION_TYPES.ADD_LOWFREQDATA,{device_id,lowFreqData}));