import { createAction } from "../../utils/reducer/reducer.utils";
import { withMatcher, ActionWithPayload } from "../../utils/reducer/reducer.utils";
import { UserData } from "../../models/user.model";
import { ALERT_ACTION_TYPES } from "./alert.types";
import { Alert } from "../../models/alert.model";
import { HttpStatusCode } from "axios";


export type FetchAlertsStart = ActionWithPayload<ALERT_ACTION_TYPES.FETCH_ALERTS_START, UserData>
export const fetchAlertsStart = withMatcher((user:UserData): FetchAlertsStart => createAction(ALERT_ACTION_TYPES.FETCH_ALERTS_START,user));

export type FetchAlertsSuccess = ActionWithPayload<ALERT_ACTION_TYPES.FETCH_ALERTS_SUCCESS, Alert[]>;
export const fetchAlertsSuccess = withMatcher((alerts:Alert[]): FetchAlertsSuccess => createAction(ALERT_ACTION_TYPES.FETCH_ALERTS_SUCCESS,alerts));

export type FetchAlertsFailed = ActionWithPayload<ALERT_ACTION_TYPES.FETCH_ALERTS_FAILED, Error>;
export const fetchAlertsFailed = withMatcher((error:Error):FetchAlertsFailed => createAction(ALERT_ACTION_TYPES.FETCH_ALERTS_FAILED,error));


export type AcknowledgeAlertStart = ActionWithPayload<ALERT_ACTION_TYPES.ACKNOWLEDGE_ALERT_START, {user:UserData, alert_id:string}>
export const acknowledgeAlertStart = withMatcher((user:UserData, alert_id:string): AcknowledgeAlertStart => createAction(ALERT_ACTION_TYPES.ACKNOWLEDGE_ALERT_START,{user, alert_id}));

export type AcknowledgeAlertSuccess = ActionWithPayload<ALERT_ACTION_TYPES.ACKNOWLEDGE_ALERT_SUCCESS, Alert>;
export const acknowledgeAlertSuccess = withMatcher((alert:Alert): AcknowledgeAlertSuccess => createAction(ALERT_ACTION_TYPES.ACKNOWLEDGE_ALERT_SUCCESS,alert));

export type AcknowledgeAlertFailed = ActionWithPayload<ALERT_ACTION_TYPES.ACKNOWLEDGE_ALERT_FAILED, Error>;
export const acknowledgeAlertFailed = withMatcher((error:Error):AcknowledgeAlertFailed => createAction(ALERT_ACTION_TYPES.ACKNOWLEDGE_ALERT_FAILED,error));


export type DeleteAlertStart = ActionWithPayload<ALERT_ACTION_TYPES.DELETE_ALERT_START, {user:UserData, alert_id:string}>
export const deleteAlertStart = withMatcher((user:UserData, alert_id:string): DeleteAlertStart => createAction(ALERT_ACTION_TYPES.DELETE_ALERT_START,{user, alert_id}));

export type DeleteAlertSuccess = ActionWithPayload<ALERT_ACTION_TYPES.DELETE_ALERT_SUCCESS, {statusCode:HttpStatusCode, alert_id:string}>;
export const deleteAlertSuccess = withMatcher((statusCode:HttpStatusCode, alert_id:string): DeleteAlertSuccess => createAction(ALERT_ACTION_TYPES.DELETE_ALERT_SUCCESS,{statusCode,alert_id}));

export type DeleteAlertFailed = ActionWithPayload<ALERT_ACTION_TYPES.DELETE_ALERT_FAILED, Error>;
export const deleteAlertFailed = withMatcher((error:Error):DeleteAlertFailed => createAction(ALERT_ACTION_TYPES.DELETE_ALERT_FAILED,error));